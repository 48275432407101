import React from "react"
import { Button, DatePicker, Form, Icon, Row, Tooltip } from "antd"
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"
import withAuth from "../withAuthHoc"
import errorIcon from "../../assets/images/Icon-Form-Error.png"
import { dateRangeMin, inputExists, stringFormat } from "./validators"
import moment from "moment-timezone"
import ComponentForm from "./componentForm"
import ComponentList from "./componentList"

const { RangePicker } = DatePicker

export class DatasetForm extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.state = {
      labelFocus: {
        date: "empty",
      },
      isAddingComponent: false,
      components: {},
    }
  }

  _renderEndAdornment(key) {
    return (
      <InputAdornment position="end">
        {this.props.form.getFieldError(key) ? (
          <img src={errorIcon} alt="error-icon"></img>
        ) : (
          ""
        )}
      </InputAdornment>
    )
  }

  _setLabelFocus(key) {
    const labelFocus = this.state.labelFocus
    if (labelFocus[key] === "empty") {
      this.setState({
        labelFocus: {
          ...labelFocus,
          [key]: "focused",
        },
      })
    }
  }

  _clearLabelFocus() {
    const labelFocus = this.state.labelFocus
    for (let k in labelFocus) {
      if (labelFocus[k] === "focused") {
        labelFocus[k] = "empty"
      }
    }
    this.setState({ labelFocus })
  }

  _renderErrorIcon(key) {
    return this.props.form.getFieldError(key) ? (
      <img
        src={errorIcon}
        alt="error-icon"
        style={{
          zIndex: "10",
          background: "white",
          position: "absolute",
          bottom: "-3px",
          right: "10px",
        }}
      ></img>
    ) : (
      ""
    )
  }

  _updateComponents(evt) {
    this.setState({
      isAddingComponent: false,
      components: { ...this.state.components, ...evt },
    })
  }

  _removeComponent(comp) {
    let components = this.state.components
    delete components[comp]
    this.setState({ components })
  }

  _submitForm() {
    this.props.onSubmit(this._parseFormData())
  }

  _parseFormData() {
    const values = this.props.form.getFieldsValue()
    const response = {
      components_field_map: this.state.components,
      dataset_name: values.dataset_name,
      t_init: values["date-range"][0].format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      t_end: values["date-range"][1].format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    }
    return response
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <Form className="formCustom">
          <Row className="content">
            <div style={{ maxWidth: "450px" }}>
              <Form.Item>
                <FormControl variant="outlined" className="form-item" fullWidth>
                  <InputLabel
                    className="knolar-input-label"
                    htmlFor={"new-dataset-form-dataset-name"}
                  >
                    {this._formatMessage("new-dataset-form-dataset-name")}
                  </InputLabel>
                  {getFieldDecorator("dataset_name", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: this._formatMessage("form-required-field"),
                      },
                      {
                        validator: stringFormat(
                          this._formatMessage("forbiden-chars")
                        ),
                      },
                      {
                        validator: inputExists(
                          this.props.datasetNames,
                          this._formatMessage("new-dataset-name-exists")
                        ),
                      },
                    ],
                  })(
                    <OutlinedInput
                      name="dataset_name"
                      onChange={this.handleChangeInputText}
                      className="knolar-dataset-input"
                      endAdornment={this._renderEndAdornment("dataset_name")}
                    />
                  )}
                </FormControl>
              </Form.Item>
              <Form.Item>
                <FormControl
                  variant="outlined"
                  className="form-item knolar-dataset-input"
                  fullWidth
                >
                  {getFieldDecorator("date-range", {
                    rules: [
                      {
                        required: true,
                        message: this._formatMessage(
                          "new-dataset-form-date-range-required"
                        ),
                      },
                      {
                        validator: dateRangeMin(
                          180,
                          this._formatMessage("new-dataset-short-range", {
                            minDays: "180",
                          })
                        ),
                      },
                    ],
                  })(
                    <RangePicker
                      className="knolar-select"
                      style={{ width: "100%" }}
                      allowClear={true}
                      onFocus={() => this._setLabelFocus("date")}
                      onBlur={() => this._clearLabelFocus()}
                      ranges={{
                        // Hoy: [moment().startOf("day"), moment().endOf("day")],
                        [this._formatMessage("last-6-months")]: [
                          moment().add(-6, "month"),
                          moment(),
                        ],
                        [this._formatMessage("last-year")]: [
                          moment().add(-1, "year"),
                          moment(),
                        ],
                        [this._formatMessage("last-2-year")]: [
                          moment().add(-2, "year"),
                          moment(),
                        ],
                      }}
                      showTime={true}
                      format="YYYY/MM/DD HH:mm:ss"
                      onChange={this._onDateRangeChange}
                    />
                  )}
                </FormControl>
                {this._renderErrorIcon("date-range")}
              </Form.Item>
              <div className="components-section-title">
                <span>
                  {this._formatMessage("new-dataset-components-title")}
                </span>
                <Tooltip
                  title={this._formatMessage("new-dataset-components-tooltip")}
                >
                  <Icon
                    type="info-circle"
                    style={{ paddingLeft: "10px", color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              </div>
              <ComponentList
                components={this.state.components}
                isEditing={true}
                removeComponent={this._removeComponent.bind(this)}
              ></ComponentList>
              {!Object.keys(this.state.components).length ||
              this.state.isAddingComponent ? (
                <ComponentForm
                  ingests={this.props.ingests}
                  onSubmit={this._updateComponents.bind(this)}
                  components={Object.keys(this.state.components)}
                ></ComponentForm>
              ) : (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="primary"
                    onClick={() => this.setState({ isAddingComponent: true })}
                    className="knolar-button dataset-button"
                  >
                    {this._formatMessage("new-component-submit")}
                  </Button>
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="primary"
                  onClick={this._submitForm.bind(this)}
                  className="knolar-button dataset-button"
                  disabled={!Object.keys(this.state.components).length}
                >
                  {this._formatMessage("new-dataset-submit")}
                </Button>
              </div>
            </div>
          </Row>
        </Form>
      </div>
    )
  }
}

export default injectIntl(
  Form.create({ name: "dataset" })(withAuth(DatasetForm))
)

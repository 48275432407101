import React from "react"
import { Button, Form, Icon, message, Select, Spin, Tooltip } from "antd"
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"
import { getFromEnrichment } from "../../services/dataset"
import withAuth from "../withAuthHoc"
import errorIcon from "../../assets/images/Icon-Form-Error.png"
import { inputExists, stringFormat } from "./validators"

const { Option } = Select
export class ComponentForm extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.state = {
      labelFocus: {
        ingest_name: "empty",
        date_column: "empty",
        value_column: "empty",
        tag_column: "empty",
        component_sensors: "empty",
        sampling_rate: "empty",
      },
      sensors: [],
      sensorsLimited: [],
      loadingSensors: false,
      tagColumn: null,
      searchSensorText: "",
      searchingSensors: false,
    }
  }

  _renderEndAdornment(key) {
    return (
      <InputAdornment position="end">
        {this.props.form.getFieldError(key) ? (
          <img src={errorIcon} alt="error-icon"></img>
        ) : (
          ""
        )}
      </InputAdornment>
    )
  }

  _setLabelFocus(key) {
    const labelFocus = this.state.labelFocus
    if (labelFocus[key] === "empty") {
      this.setState({
        labelFocus: {
          ...labelFocus,
          [key]: "focused",
        },
      })
    }
  }

  _clearLabelFocus() {
    const labelFocus = this.state.labelFocus
    for (let k in labelFocus) {
      if (labelFocus[k] === "focused") {
        labelFocus[k] = "empty"
      }
    }
    this.setState({ labelFocus })
  }

  _onIngestSelect(evt) {
    const ingest = this.props.ingests.find(item => item.ingest_id === evt)
    getFromEnrichment(ingest.metadataTable)
      .then(sensors => {
        this.setState({
          loadingSensors: false,
          sensors,
        })
        if (this.state.tagColumn) {
          this._filterSensors(this.state.tagColumn)
        }
      })
      .catch(() => this.setState({ sensors: null }))
    this.setState({
      ingest,
      sensors: [],
      loadingSensors: true,
      labelFocus: {
        ...this.state.labelFocus,
        ingest_name: "setted",
      },
    })
  }

  _onColumnSelect(evt, key) {
    this.setState({
      labelFocus: {
        ...this.state.labelFocus,
        [key]: "setted",
      },
    })
    if (key === "tag_column") {
      this.setState({ tagColumn: evt })
      if (!this.state.loadingSensors) {
        this._filterSensors(evt)
      }
    }
  }

  _filterSensors(tagColumn) {
    const sensorsLimited = this.state.sensors
      .filter(e => tagColumn in e)
      .map(e => e[tagColumn])
      .slice(0, 50)
    this.setState({
      sensorsLimited,
    })
  }

  _onTagPicked(evt) {
    const newTag = evt[evt.length - 1]
    if (
      this.props.form.getFieldValue("component_sensors").length < evt.length
    ) {
      getFromEnrichment(this.state.ingest.metadataTable, newTag)
        .then(() =>
          message.info(
            this._formatMessage("new-component-form-tag-found", { newTag })
          )
        )
        .catch(error => {
          this.props.form.setFieldsValue({
            component_sensors: evt.slice(0, -1),
          })
          message.error(
            this._formatMessage("new-component-form-tag-not-found", { newTag })
          )
        })
      this.setState({
        labelFocus: {
          ...this.state.labelFocus,
          component_sensors: "setted",
        },
      })
    }
  }

  _parseComponentData() {
    const values = this.props.form.getFieldsValue()
    const component = {
      [values.component_name]: {
        ingest_alias: this.state.ingest.ingest_alias,
        columns: {
          date: values.date_column,
          value: values.value_column,
          tag: values.tag_column,
        },
        ingest_id: values.ingest_name,
        tags: values.component_sensors,
      },
    }
    return component
  }

  _submitForm() {
    this.props.form.validateFields(err => {
      if (err) {
        return message.error(
          this._formatMessage("page-user-form-generic-error-text")
        )
      } else {
        this.props.onSubmit(this._parseComponentData())
      }
    })
  }

  // _onSearchSensor(searchText) {
  //   this.setState({ searchSensorText: searchText })
  //   if (searchText.length >= 4) {
  //     this.setState({ searchingSensors: true })
  //     getFromEnrichment(this.state.ingest.metadataTable, searchText).then(
  //       sensors => {
  //         this.setState({
  //           searchingSensors: false,
  //           sensorsLimited: sensors
  //             .map(e => e[this.state.tagColumn])
  //             .slice(0, 50),
  //         })
  //       }
  //     )
  //   } else {
  //     this.setState({ sensorsLimited: [] })
  //   }
  // }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form className="formCustom dataset">
        <Form.Item>
          <FormControl variant="outlined" className="form-item" fullWidth>
            <InputLabel
              className="knolar-input-label"
              htmlFor={"new-component-form-component-name"}
            >
              {this._formatMessage("new-component-form-component-name")}
            </InputLabel>
            {getFieldDecorator("component_name", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: this._formatMessage("form-required-field"),
                },
                {
                  validator: inputExists(
                    this.props.components,
                    this._formatMessage("new-component-name-exists")
                  ),
                },
                {
                  validator: stringFormat(
                    this._formatMessage("forbiden-chars")
                  ),
                },
              ],
            })(
              <OutlinedInput
                name="component_name"
                onChange={this.handleChangeInputText}
                className="knolar-dataset-input"
                endAdornment={this._renderEndAdornment("dataset_name")}
                autoComplete="off"
              />
            )}
          </FormControl>
        </Form.Item>

        <Form.Item>
          <FormControl variant="outlined" className="form-item" fullWidth>
            <InputLabel
              className={`knolar-input-label height-50 ${this.state.labelFocus.ingest_name}`}
              htmlFor="ingest_name"
              // disabled={!this.state.realTimeEnabled}
            >
              {this._formatMessage("new-component-form-ingest-name")}
            </InputLabel>
            {getFieldDecorator("ingest_name", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: this._formatMessage("form-required-field"),
                },
              ],
            })(
              <Select
                name="ingest_name"
                className="knolar-select height-50"
                onFocus={() => this._setLabelFocus("ingest_name")}
                onBlur={() => this._clearLabelFocus()}
                style={{ width: "100%" }}
                onChange={this._onIngestSelect.bind(this)}
                showSearch={true}
              >
                {this.props.ingests &&
                  this.props.ingests.map(
                    ({ ingest_id, ingest_alias, metadataTable }) => (
                      <Option
                        key={ingest_id}
                        value={ingest_id}
                        disabled={!metadataTable}
                      >
                        {ingest_alias}
                      </Option>
                    )
                  )}
              </Select>
            )}
          </FormControl>
        </Form.Item>
        {this.state.ingest ? (
          <div className="component-columns">
            <Form.Item>
              <FormControl variant="outlined" className="form-item" fullWidth>
                <InputLabel
                  className={`knolar-input-label height-50 ${this.state.labelFocus["date_column"]}`}
                  htmlFor="date-column"
                >
                  {this._formatMessage("new-component-form-date-column")}
                </InputLabel>
                {getFieldDecorator("date_column", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: this._formatMessage("form-required-field"),
                    },
                  ],
                })(
                  <Select
                    name="date_column"
                    className="knolar-select height-50"
                    onFocus={() => this._setLabelFocus("date_column")}
                    onBlur={() => this._clearLabelFocus()}
                    style={{ width: "100%" }}
                    onChange={val => this._onColumnSelect(val, "date_column")}
                  >
                    {this.state.ingest.fields
                      .filter(field => field.property === "TS_INDEX")
                      .map(field => (
                        <Option key={field.name} value={field.name}>
                          {field.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormControl>
            </Form.Item>
            <Form.Item>
              <FormControl variant="outlined" className="form-item" fullWidth>
                <InputLabel
                  className={`knolar-input-label height-50 ${this.state.labelFocus["value_column"]}`}
                  htmlFor="value_column"
                >
                  {this._formatMessage("new-component-form-value-column")}
                </InputLabel>
                {getFieldDecorator("value_column", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: this._formatMessage("form-required-field"),
                    },
                  ],
                })(
                  <Select
                    name="value_column"
                    className="knolar-select height-50"
                    onFocus={() => this._setLabelFocus("value_column")}
                    onBlur={() => this._clearLabelFocus()}
                    style={{ width: "100%" }}
                    onChange={val => this._onColumnSelect(val, "value_column")}
                    showSearch={true}
                  >
                    {this.state.ingest.fields
                      .filter(field => field.property === "METRIC")
                      .map(field => (
                        <Option key={field.name} value={field.name}>
                          {field.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormControl>
            </Form.Item>
            <Form.Item>
              <FormControl variant="outlined" className="form-item" fullWidth>
                <InputLabel
                  className={`knolar-input-label height-50 ${this.state.labelFocus["tag_column"]}`}
                  htmlFor="tag_column"
                >
                  {this._formatMessage("new-component-form-tag-column")}
                </InputLabel>
                {getFieldDecorator("tag_column", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      message: this._formatMessage("form-required-field"),
                    },
                  ],
                })(
                  <Select
                    name="tag_column"
                    className="knolar-select height-50"
                    onFocus={() => this._setLabelFocus("tag_column")}
                    onBlur={() => this._clearLabelFocus()}
                    style={{ width: "100%" }}
                    onChange={val => this._onColumnSelect(val, "tag_column")}
                    showSearch={true}
                  >
                    {this.state.ingest.fields
                      .filter(field => field.type === "STRING")
                      .map(field => (
                        <Option key={field.name} value={field.name}>
                          {field.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormControl>
            </Form.Item>
          </div>
        ) : null}
        {this.props.form.getFieldValue("tag_column") ? (
          !this.state.loadingSensors ? (
            <div>
              <div className="components-section-title">
                <span>{this._formatMessage("new-dataset-sensors-title")}</span>
                <Tooltip
                  title={this._formatMessage("new-dataset-sensors-tooltip")}
                >
                  <Icon
                    type="info-circle"
                    style={{ paddingLeft: "10px", color: "rgba(0,0,0,.45)" }}
                  />
                </Tooltip>
              </div>

              <Form.Item>
                <FormControl variant="outlined" className="form-item" fullWidth>
                  <InputLabel
                    className={`knolar-input-label height-50 ${this.state.labelFocus.component_sensors}`}
                    htmlFor="component_sensors"
                  >
                    {this._formatMessage(
                      "new-component-form-component-sensors"
                    )}
                  </InputLabel>
                  {getFieldDecorator("component_sensors", {
                    initialValue: [],
                    rules: [
                      {
                        required: true,
                        message: this._formatMessage("form-required-field"),
                      },
                    ],
                  })(
                    <Select
                      name="component_sensors"
                      mode="tags"
                      className="knolar-select height-50"
                      onFocus={() => this._setLabelFocus("component_sensors")}
                      onBlur={() => this._clearLabelFocus()}
                      style={{ width: "100%" }}
                      onChange={this._onTagPicked.bind(this)}
                      // showSearch={true}
                      // onSearch={searchText => this._onSearchSensor(searchText)}
                      // loading={this.state.searchingSensors}
                    >
                      {/* {this.state.searchSensorText.length > 3 &&
                      this.state.sensorsLimited.map(d => (
                        <Option key={d} value={d}>
                          {d}
                        </Option>
                      ))} */}
                    </Select>
                  )}
                </FormControl>
              </Form.Item>
            </div>
          ) : this.state.sensors === null ? (
            <div style={{ color: "red", marginBottom: ".5em" }}>
              {this._formatMessage("page-dataset-loading-sensors-ko")}
            </div>
          ) : (
            <div className="components-section-title">
              <Spin size="large" style={{ marginLeft: "1em" }}></Spin>
              {this._formatMessage("page-dataset-loading-sensors")}
            </div>
          )
        ) : null}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={this._submitForm.bind(this)}
            className="knolar-button dataset-button"
          >
            {this._formatMessage("new-component-submit")}
          </Button>
        </div>
      </Form>
    )
  }
}

export default injectIntl(
  Form.create({ name: "component" })(withAuth(ComponentForm))
)

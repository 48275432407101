import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { Col, Icon, message, Row, Spin } from "antd"
import Template from "../../layouts/base"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import DatasetForm from "../../components/dataset/datasetForm"
import { getDictionaries, getIngests } from "../../services/ingest"
import { createDataset, getDatasets } from "../../services/dataset"
import ConfirmationModal from "../../components/dataset/confirmationModal"
import { BsArrowBarLeft } from "react-icons/bs"

class NewDataset extends React.Component {
  constructor(props) {
    super(props)

    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })

    this.state = {
      loading: true,
      ingests: null,
      confirmationModalVisible: false,
    }
  }

  componentDidMount() {
    this._setDatasetNames()
    this._loadIngests()
  }

  async _setDatasetNames() {
    if (!this.props.location.state) {
      this.setState({ loading: true })
      let datasetNames = []
      await getDatasets().then(
        res => (datasetNames = res.map(ds => ds.dataset_name))
      )
      this.setState({ datasetNames, loading: false })
    } else {
      this.setState({ datasetNames: this.props.location.state.datasetNames })
    }
  }

  _onLoadIngestsError(error) {
    const errorMessage = this.props.intl.formatMessage(
      { id: "page-ingestions-load-ingestions-ko" },
      { error: error.message }
    )
    console.error(`[ERROR] Loading ingests: \n${error.message || error}`)
    message.error(errorMessage)
  }

  async _loadIngests() {
    let ingests = []
    let dictionaries = []
    await getDictionaries().then(dict => (dictionaries = dict))
    await getIngests()
      .then(data => {
        ingests = data.map(i => {
          const metadataTable = i.enrichments.length
            ? i.enrichments[0].metadataTable
            : null
          let ingest = {
            ingest_id: i.id,
            ingest_alias: i.alias,
          }
          if (metadataTable) {
            let dictionary = dictionaries.find(d => d.id === i.dictionaryId)
            let fields = dictionary.fields
            ingest = { ...ingest, metadataTable, fields }
          }
          return ingest
        })
      })
      .catch(err => this._onLoadIngestsError(err))
    this.setState({ ingests, loading: false })
  }

  _onSubmit(data) {
    createDataset(data)
      .then(() =>
        message.info(this._formatMessage("page-dataset-create-dataset-ok"))
      )
      .catch(() =>
        message.error(this._formatMessage("page-dataset-create-dataset-ko"))
      )

    setTimeout(() => {
      navigate(`/dataset`)
    }, 1500)
  }

  _onBackCancel() {
    this.setState({ confirmationModalVisible: false })
  }

  _onBackConfirm() {
    this.setState({ confirmationModalVisible: false })
    navigate("/dataset")
  }

  render() {
    const _thatIntl = this.props.intl
    return (
      <Template selected={["dataset", "ingest-new"]}>
        <CustomBreadcrumb
          crumbs={[
            {
              label: this._formatMessage("menu-datasets"),
              link: "/dataset",
            },
            this._formatMessage("new-dataset-breadcrumb"),
          ]}
        />
        <Row>
          <Col className="knolar-intro">
            <Icon
              component={BsArrowBarLeft}
              onClick={() => this.setState({ confirmationModalVisible: true })}
            ></Icon>
            {_thatIntl.formatMessage({ id: "new-dataset-breadcrumb" })}
          </Col>
        </Row>
        {this.state.loading ? (
          <div>
            <div className="datasets-section-title">
              <Spin size="large" style={{ marginRight: "1em" }}></Spin>
              {this._formatMessage("page-dataset-loading-ingests")}
            </div>
            {/* <Spin size="large"></Spin>
          Cargando ingesas */}
          </div>
        ) : (
          <DatasetForm
            ingests={this.state.ingests}
            datasetNames={this.state.datasetNames}
            onSubmit={this._onSubmit.bind(this)}
          ></DatasetForm>
        )}
        <ConfirmationModal
          visible={this.state.confirmationModalVisible}
          onOk={() => this._onBackConfirm()}
          onCancel={() => this._onBackCancel()}
        ></ConfirmationModal>
      </Template>
    )
  }
}

export default injectIntl(NewDataset)

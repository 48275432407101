import React from "react"
import { Button, Checkbox, Icon, Modal, Alert } from "antd"
import { injectIntl } from "gatsby-plugin-intl"

export class ConfirmationModal extends React.Component {
  render() {
    const { visible } = this.props
    return (
      <Modal visible={visible} footer={null} closable={null}>
        <div className="model-cancel-modal-container">
          <div className="model-cancel-modal-header-container">
            <div className="model-cancel-modal-header-circle">
              <Icon
                type="exclamation"
                style={{
                  color: "orange",
                  fontSize: "40px",
                  textAlign: "center",
                }}
              />
            </div>
            <p className="model-cancel-modal-header-content">
              {this.props.intl.formatMessage({
                id: "exit",
              })}
            </p>
          </div>
          <div className="model-delete-modal-content">
            <p>
              {this.props.intl.formatMessage({
                id: "model-cancel-modal-confirmation",
              })}
            </p>
            <p style={{ fontSize: "1em" }}>
              {this.props.intl.formatMessage({
                id: "model-cancel-modal-content-advice",
              })}
            </p>
          </div>
          <div className="model-cancel-modal-buttons">
            <Button
              className="knolar-button back"
              onClick={this.props.onCancel}
            >
              {this.props.intl.formatMessage({ id: "cancel" })}
            </Button>
            <Button
              className="knolar-button"
              type="primary"
              onClick={this.props.onOk}
            >
              {this.props.intl.formatMessage({
                id: "exit",
              })}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}
export default injectIntl(ConfirmationModal)

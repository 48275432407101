import moment from "moment"

export function inputExists(existingValues, errorMsg) {
  return (rule, value, cb) => {
    if (value) {
      try {
        if (existingValues.includes(value)) {
          throw new Error(errorMsg)
        } else {
          cb()
        }
      } catch (err) {
        cb(err)
      }
    } else {
      cb()
    }
  }
}

export function stringFormat(errorMsg) {
  return (rule, value, cb) => {
    if (value) {
      try {
        if (value.match(/[^a-z0-9_-]/gi)) {
          throw new Error(errorMsg)
        } else {
          cb()
        }
      } catch (err) {
        cb(err)
      }
    } else {
      cb()
    }
  }
}
export function dateRangeMin(minDays, errorMsg) {
  return (rule, value, cb) => {
    if (value && value.length) {
      try {
        if (moment.duration(value[1].diff(value[0])).asDays() < minDays) {
          throw new Error(errorMsg)
        } else {
          cb()
        }
      } catch (err) {
        cb(err)
      }
    } else {
      cb()
    }
  }
}

// export function firstTrainingRate(date, errorMsg) {
//   return (rule, value, cb) => {
//     if (value) {
//       try {
//         if (!date) {
//           throw new Error(errorMsg)
//         } else {
//           cb()
//         }
//       } catch (err) {
//         cb(err)
//       }
//     } else {
//       cb()
//     }
//   }
// }

export function overlapDates(date, errorMsg) {
  return (rule, value, cb) => {
    if (value && date) {
      try {
        if (
          (value[1] > date[0] && value[0] < date[1]) ||
          (value[1] < date[0] && value[0] > date[1])
        ) {
          throw new Error(errorMsg)
        } else {
          cb()
        }
      } catch (err) {
        cb(err)
      }
    } else {
      cb()
    }
  }
}

export function dateRangeInDataset(init, end, errorMsg) {
  return (rule, value, cb) => {
    if (value && value.length) {
      try {
        if (
          moment.duration(value[0].diff(moment(init))).asSeconds() < 0 ||
          moment.duration(moment(end).diff(value[1])).asSeconds() < 0
        ) {
          throw new Error(errorMsg)
        } else {
          cb()
        }
      } catch (err) {
        cb(err)
      }
    } else {
      cb()
    }
  }
}

export function rangeInTagged(taggedRanges, trainingRange, errorMsg) {
  return (rule, value, cb) => {
    if (value && value.length) {
      try {
        if (!taggedRanges) {
          throw new Error(errorMsg["noTaggedRange"])
        }
        if (!trainingRange) {
          throw new Error(errorMsg["noTrainingRange"])
        }
        taggedRanges.forEach(range => {
          let notInTrainingRange = false
          let notInValidatingRange = false
          if (
            trainingRange[0] > moment(range[0]) ||
            trainingRange[1] < moment(range[1])
          ) {
            notInTrainingRange = true
          }
          if (
            moment(value[0]) > moment(range[0]) ||
            moment(value[1]) < moment(range[1])
          ) {
            notInValidatingRange = true
          }
          if (notInTrainingRange && notInValidatingRange) {
            throw new Error(errorMsg["outOfRange"])
          }
        })
        cb()
      } catch (err) {
        cb(err)
      }
    } else {
      cb()
    }
  }
}

export function csvDateRanges(errorMessages, init, end) {
  return (rule, value, cb) => {
    if (value && value.file.status === "done") {
      const reader = new FileReader()
      reader.readAsText(value.file.originFileObj)
      reader.onload = () => {
        const parsedDates = parseDates(reader.result)
        const errorType = checkDates(parsedDates, init, end)
        try {
          if (errorType) {
            throw new Error(errorMessages[errorType])
          } else {
            cb()
          }
        } catch (err) {
          cb(err)
        }
      }
    } else {
      cb()
    }
  }
}

export function parseDates(dates) {
  const result = dates.split("\n")
  let data = []
  for (let i in result) {
    data.push(result[i].split(","))
  }
  return data.sort()
}

export function checkDates(dates, init, end) {
  while (dates.length) {
    const current = dates.splice(0, 1)[0]
    if (
      !moment(current[0], "YYYY-MM-DD HH:mm:ss", true).isValid() ||
      !moment(current[1], "YYYY-MM-DD HH:mm:ss", true).isValid()
    ) {
      return "formatError"
    }
    if (
      moment.duration(moment(current[0]).diff(moment(init))).asSeconds() < 0 ||
      moment.duration(moment(end).diff(moment(current[1]))).asSeconds() < 0
    ) {
      return "outOfDataset"
    }
    if (current[0] > current[1]) {
      return "overlapInRange"
    }
    for (let i in dates) {
      if (current[1] > dates[i][0]) {
        return "overlap"
      }
    }
  }
  return false
}

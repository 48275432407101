import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { Collapse } from "antd"
const { Panel } = Collapse

export class ComponentList extends React.Component {
  render() {
    const { components } = this.props
    return (
      <div className="component-list">
        {Object.keys(components).map(component => (
          <div
            className={`item ${this.props.isEditing ? "isEditing" : ""}`}
            key={component}
          >
            <div className="item-title">{component}</div>
            <div className="item-content">
              <div>{components[component].ingest_alias}</div>
              <Collapse
                defaultActiveKey={null}
                bordered={false}
                accordion={true}
              >
                <Panel
                  key="1"
                  header={
                    <div>
                      {`${
                        components[component].tags.length
                      } ${this.props.intl.formatMessage({
                        id: "new-dataset-sensors-title",
                      })}`}
                    </div>
                  }
                >
                  <div style={{ maxHeight: "80px", overflow: "auto" }}>
                    {components[component].tags.map(tag => (
                      <div key={tag}>{tag}</div>
                    ))}
                  </div>
                </Panel>
              </Collapse>
            </div>
            {this.props.isEditing ? (
              <div
                className="delete-button"
                onClick={evt => this.props.removeComponent(component)}
              >
                {this.props.intl.formatMessage({ id: "erase" })}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    )
  }
}

export default injectIntl(ComponentList)
